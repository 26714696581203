import React, { useEffect, useState } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'

function LoadVideoCall(props) {

    const APP_ID = process.env.REACT_APP_KEY
    const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
    const [remoteStreams, setRemoteStreams] = useState([]);

    const handleUserPublish = async (user, mediaType) => {
          // Subscribe to the remote user when the SDK triggers the "user-published" event.
          await client.subscribe(user, mediaType)
          let channelParameters = {}
          // Subscribe and play the remote video in the container If the remote user publishes a video track.
          if (mediaType == 'video') {
            // Retrieve the remote video track.
            channelParameters.remoteVideoTrack = user.videoTrack
            // Retrieve the remote audio track.
            channelParameters.remoteAudioTrack = user.audioTrack
            // Save the remote user id for reuse.
            channelParameters.remoteUid = user.uid.toString()
            setRemoteStreams([channelParameters]);
          }
          // Subscribe and play the remote audio track If the remote user publishes the audio track only.
          if (mediaType == 'audio' && user.audioTrack) {
            // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
            channelParameters.remoteAudioTrack = user.audioTrack
            // Play the remote audio track. No need to pass any DOM element.
            channelParameters.remoteAudioTrack.play()
          }
      }
    client.on('user-published', handleUserPublish)
        
    client.on('user-unpublished', user => {
      const remainingRemoteStreams = remoteStreams.filter(s=>s.remoteUid != user.uid.toString())
      if(remoteStreams.length != 0)setRemoteStreams(remainingRemoteStreams)
    });

    const loadVideoCall = async(channel, token, uid) =>{
        try{
            await client.join(APP_ID, channel, token, uid)
            let localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
            let localVideoTrack = await AgoraRTC.createCameraVideoTrack()
            props.setLocalMedia([localAudioTrack, localVideoTrack])
            // Publish the local audio and video tracks in the channel.
            await client.publish([localAudioTrack, localVideoTrack])
            let localPlayerContainer = document.getElementById('agora_local')
            localPlayerContainer.innerHTML = ''
            localVideoTrack.play(localPlayerContainer);
            props.setClient(client);
        }
        catch(error){
          console.log(error);
        }
    }
    useEffect(()=>{
        if (props.channel && props.userToken) {
            loadVideoCall(props.channel, props.userToken.token , props.userToken.uid);
          }
    },[props.channel, props.userToken])

    useEffect(() => {
        if (remoteStreams.length > 0 && remoteStreams[0].remoteVideoTrack){
          remoteStreams[0].remoteVideoTrack.play(
            'agora_remote' + remoteStreams[0].remoteUid
          )
        }
      }, [remoteStreams])
    return (
        <>
            {remoteStreams && Object.keys(remoteStreams).length === 0 && (
                <>
                    <div class='doc-image'></div>
                    <h4>Connecting...</h4>
                </>
            )}
                  
            {remoteStreams 
                && Object.keys(remoteStreams)
                .map(key => {
                    let stream = remoteStreams[key]
                    let streamId = stream.remoteUid
                    return (
                        <div
                            class='doc-image'
                            key={streamId}
                            id={`agora_remote${streamId}`}>

                        </div>
                    )}
                )
            }
        </>
    )
}
  
export default LoadVideoCall;