import React from 'react'
import Moment from 'moment'
import Joi from 'joi-browser'
import { connect } from 'react-redux'
import Form from '../../../common/form/form'
import { withRouter } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable'
import star from '../../../../include/images/start-0.svg'
import close from '../../../../include/images/icon-close.svg'
import closeIcon from '../../../../include/images/close.svg'
import bluestar from '../../../../include/images/blue-star-icon.svg'
import file from '../../../../include/images/prescription-file-icon.svg'
import Select from 'react-select'
import { Modal } from 'react-bootstrap'
import SelectOptions from '../../common/selectOptions'

import {
  loadFormula,
  getPrescription,
  loadComposition,
  loadFrequency,
  loadBrand,
  loadDose,
  loadDay,
  addFormula,
  addComposition,
  addFrequency,
  addBrand,
  addDose,
  addRadiology,
  loadRadiology,
  loadLabinvestigation,
  addLabinvestigation,
  addPrescription,
  loadForAppointment,
  loadTechniques,
  loadConducts,
  loadmedicinalPlan,
  loadForPatient,
  loadList,
} from '../../../../store/prescription'
import moment from 'moment'
import _ from 'lodash'
import { toast } from 'react-toastify'
import AlertError from '../../../common/alertError'
import AlertSuccess from '../../../common/alertSuccess'
import { getFollowup, loadTherapy } from '../../../../store/followUp'
import { patientDetail } from '../../../../store/patient'
import { loadFieldType, getMisc } from '../../../../store/misc'
import { loadDoctors, getDoctor } from '../../../../store/doctor'
import AvtarImage from '../../../common/avtarImage'

const participants = [
  { Participants: 'Patient' },
  { Participants: 'Partner' },
  { Participants: 'Family' },
  { Participants: 'Other' },
]

class IncompletePrescription extends Form {
  state = {
    formclass: 'form-group custom-input',
    toolbox: false,
    session_participants: [],
    medical_prescription: [],
    editMedicineIdx: -1,
    technique: [],
    popup1: false,
    objective: [],
    data: {
      key_issue: '',
      notes_data: '',
      chief_complaints: '',
      day: '',
      duration_of_session: '',
      diagnosis: '',
      frequency: '',
      notes: '',
      plan_for_the_next_session: '',
      objective_of_the_next_session: '',
      followup_session: '',
      referral: '',
      referral_doctor_id: '',
      nextFollowUp: '',
      to_chemist: '',
      special_advice: '',
      formulation: {},
      composition: {},
      brand: {},
      dose: {},
      radiology: [],
      techniques: [],
      conduct: [],
      labinvestigation: [],
      medicinalPlan: [],
      theray_method: {},
      therapy_technique: [],
    },
    errors: {},
    is_submitted: false,
    selectPrescription: false,
    presId: '',
  }

  handleFocus = () => {
    this.setState({ formclass: 'form-group custom-input show on' })
  }

  handleBlur = e => {
    const classname = e.target.value
      ? 'form-group custom-input show on'
      : 'form-group custom-input '

    if (e.target.value && Number(e.target.value)) {
      this.setState({
        formclass: classname,
        data: {
          ...this.state.data,
        },
      })
    } else {
      this.setState({ formclass: classname })
    }
  }

  handleBlur2 = e => {
    const classname = e.target.value
      ? 'form-group custom-input show on'
      : 'form-group custom-input '

    if (e.target.value && Number(e.target.value)) {
      this.setState({
        formclass: classname,
        data: {
          ...this.state.data,
          nextFollowUp: moment()
            .add(Number(e.target.value), 'days')
            .format('YYYY-MM-DD'),
        },
      })
    } else {
      this.setState({ formclass: classname })
    }
  }


  componentDidMount() {
    this.props.loadFieldType()
    this.props.loadFormula()
    this.props.loadComposition()
    this.props.loadFrequency()
    this.props.loadConducts()
    this.props.loadmedicinalPlan()
    this.props.loadTechniques()
    //this.props.loadDose();
    this.props.loadDay()
    this.props.loadRadiology()
    this.props.loadLabinvestigation()
    this.props.loadTherapy()
    const parent = this.props.therapy.find(
      e => e.title === this.state.data.theray_method.value
    )
    this.setState({ technique: parent && parent.techniques })
  }
  toggleDuration = e => {
    const data = { ...this.state.data }
    data.duration_of_session = e.target.id
    this.setState({ data })
  }
  changeLang = Lang => {
    let session_participants = [...this.state.session_participants]

    const find = session_participants.find(v => v === Lang)
    if (find) {
      const f = session_participants.filter(vf => vf !== Lang)
      session_participants = f
    } else {
      session_participants.push(Lang)
    }
    this.setState({ session_participants })
  }
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      'minHeight': 45,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
  }
  handleCreateformula = inputValue => {
    if (inputValue) {
      const data = { ...this.state.data }
      data.formulation = { value: inputValue, label: inputValue }
      this.setState({ data })
      const formdata = {
        title: inputValue,
      }
      this.props.addFormula(formdata, this.callBack1)
    }
  }
  callBack1 = res => {
    if (res && res.status === 200) {
      this.props.loadFormula()
    }
  }

  addData = () => {
    const medical_prescription = [...this.state.medical_prescription]
    const { formulation, composition, brand, dose, frequency, day, notes } =
      this.state.data

    if (!formulation.label || !composition.label || !brand?.label || !dose?.label || !frequency) 
      return false

    let arr = []
    if (this.state.editMedicineIdx >= 0) { 
      medical_prescription.map((ele, i) => {
        if (i == this.state.editMedicineIdx) {
          arr.push({
            formulation: formulation.label,
            composition: composition.label,
            brand: brand.label,
            dose: dose.label,
            frequency: frequency,
            days: day,
            notes: notes,
          })
        } else {
          arr.push(ele)
        }
      })
    } else {
      medical_prescription.push({
        formulation: formulation.label,
        composition: composition.label,
        brand: brand.label,
        dose: dose.label,
        frequency: frequency,
        days: day,
        notes: notes,
      })
    }
    const data = { ...this.state.data }
    data.formulation = ''
    data.composition = ''
    data.brand = ''
    data.dose = ''
    data.frequency = ''
    data.day = ''
    data.notes = ''
    this.setState({
      medical_prescription:
        this.state.editMedicineIdx >= 0 ? arr : medical_prescription,
      data,
      editMedicineIdx: -1,
    })
  }

  editData = idx => {
    let valueObj = { ...this.state.medical_prescription[idx] }
    let data = { ...this.state.medical_prescription[idx] }

    let formatObj = {
      formulation: {},
      composition: {},
      brand: {},
      dose: {},
      day: '',
      frequency: '',
      notes: '',
    }

    const { formula, composition, brand, dose, radiology, lab, frequency } =
      this.props

    const findFormulation = formula.find(
      ele => ele.title.toLowerCase() == data.formulation.toLowerCase()
    )
    const findComposition = composition.find(
      ele => ele.title.toLowerCase() == data.composition.toLowerCase()
    )

    const findBrand = brand.find(
      ele => ele.title.toLowerCase() == data.brand.toLowerCase()
    )
    const findDose = dose.find(
      ele => ele.title.toLowerCase() == data.dose.toLowerCase()
    )

    formatObj.day = valueObj.days
    formatObj.frequency = valueObj.frequency
    formatObj.notes = valueObj.notes
    formatObj.formulation = findFormulation
      ? { label: findFormulation.title, value: findFormulation._id }
      : ''
    formatObj.composition = findComposition
      ? {
          label: findComposition.title && findComposition.title.toUpperCase(),
          value: findComposition._id,
        }
      : ''
    formatObj.brand = findBrand
      ? {
          label: findBrand.title && findBrand.title.toUpperCase(),
          value: findBrand._id,
        }
      : ''
    formatObj.dose = findDose
      ? {
          label: findDose.title && findDose.title.toUpperCase(),
          value: findDose._id,
        }
      : ''
    if (formatObj.composition && formatObj.composition.label) {
      this.props.loadBrand(
        {
          composition: formatObj.composition.label,
        },
        this.handleBrandCallback
      )
      this.props.loadDose(
        {
          composition: formatObj.composition.label,
        },
        this.handleDoseCallback
      )
    }

    this.setState({ editMedicineIdx: idx, data: formatObj })
  }

  handleDoseCallback = res => {
    let data = {
      ...this.state.medical_prescription[this.state.editMedicineIdx],
    }
    if (res && res.data && res.data.data) {
      const findData = res.data.data.find(
        ele => ele.title.toLowerCase() == data.dose.toLowerCase()
      )
      if (findData) {
        this.setState({
          data: {
            ...this.state.data,
            dose: {
              label: findData.title && findData.title.toUpperCase(),
              value: findData._id,
            },
          },
        })
      }
    }
  }
  handleBrandCallback = res => {
    let data = {
      ...this.state.medical_prescription[this.state.editMedicineIdx],
    }
    if (res && res.data && res.data.data) {
      const findData = res.data.data.find(
        ele => ele.title.toLowerCase() == data.brand.toLowerCase()
      )
      if (findData) {
        this.setState({
          data: {
            ...this.state.data,
            brand: {
              label: findData.title && findData.title.toUpperCase(),
              value: findData._id,
            },
          },
        })
      }
    }
  }

  handleCreatecomposition = inputValue => {
    if (inputValue) {
      const data = { ...this.state.data }
      data.composition = {
        value: inputValue.toUpperCase(),
        label: inputValue.toUpperCase(),
      }
      this.setState({ data })
      const formdata = {
        title: inputValue,
      }
      this.props.addComposition(formdata, this.callBack2)
    }
  }
  handleCreateFrequency = inputValue => {
    if (inputValue) {
      const data = { ...this.state.data }
      data.frequency = [
        ...data.frequency,
        {
          value: inputValue.toUpperCase(),
          label: inputValue.toUpperCase(),
        },
      ]
      this.setState({ data })
      const formdata = {
        title: inputValue.toLowerCase(),
      }

      this.props.addFrequency(formdata, this.callBackFrequency)
    }
  }
  callBackFrequency = res => {
    if (res && res.status === 200) {
      this.props.loadFrequency()
    }
  }
  callBack2 = res => {
    if (res && res.status === 200) {
      this.props.loadComposition()
    }
  }
  handleCreatebrand = inputValue => {
    if (inputValue) {
      const data = { ...this.state.data }
      data.brand = {
        value: inputValue.toUpperCase(),
        label: inputValue.toUpperCase(),
      }
      this.setState({ data })
      const formdata = {
        title: inputValue,
        composition: this.state.data.composition.label,
      }
      this.props.addBrand(formdata, this.callBack3)
    }
  }
  callBack3 = res => {
    if (res && res.status === 200) {
      this.props.loadBrand({ composition: this.state.data.composition.value })
    }
  }
  handleCreatedose = inputValue => {
    if (inputValue) {
      const data = { ...this.state.data }
      data.dose = {
        value: inputValue.toUpperCase(),
        label: inputValue.toUpperCase(),
      }
      this.setState({ data })
      const formdata = {
        composition: this.state.data.composition.label,
        title: inputValue,
      }
      this.props.addDose(formdata, this.callBack4)
    }
  }
  callBack4 = res => {
    if (res && res.status === 200) {
      this.props.loadDose()
    }
  }
  handleCreateradiology = inputValue => {
    if (inputValue) {
      const data = { ...this.state.data }
      data.radiology = [...data.radiology, { value: inputValue.toUpperCase(), label: inputValue.toUpperCase()}]
      this.setState({ data })
      const formdata = {
        title: inputValue,
      }
      this.props.addRadiology(formdata, this.callBack5)
    }
  }
  callBack5 = res => {
    if (res && res.status === 200) {
      this.props.loadRadiology()
    }
  }
  handleCreatelabinvestigation = inputValue => {
    if (inputValue) {
      const data = { ...this.state.data }
      data.labinvestigation = [...data.labinvestigation, { value: inputValue.toUpperCase(), label: inputValue.toUpperCase()}]
      this.setState({ data })
      const formdata = {
        title: inputValue,
      }
      this.props.addLabinvestigation(formdata, this.callBack6)
    }
  }
  callBack6 = res => {
    if (res && res.status === 200) {
      this.props.loadLabinvestigation()
    }
  }

  doSubmit = () => {
    this.setState(
      {
        errors: {},
      },
      () => {
        const id = this.props.id
        const {
          key_issue,
          diagnosis,
          labinvestigation,
          radiology,
          to_chemist,
          special_advice,
          formulation,
          referral,
          composition,
          brand,
          dose,
          frequency,
          plan_for_the_next_session,
          day,
          notes,
          medicinalPlan,
          conduct,
          followup_session,
          theray_method,
          therapy_technique,
          duration_of_session,
          chief_complaints,
          notes_data,
          techniques,
          referral_doctor_id,
          nextFollowUp,
        } = this.state.data

        const {
          session_participants,
          is_submitted,
          medical_prescription,
          objective,
        } = this.state
        var formdata

        formdata = {
          key_issue,
          notes_data,
          chief_complaints,
          objective,
          referral_doctor_id: referral_doctor_id ? referral_doctor_id._id || 'any': '',
          nextFollowUp: nextFollowUp ? nextFollowUp + 'T00:00:00.00Z' : '',
          is_submited: is_submitted,
          session_participants: session_participants,
          diagnosis: diagnosis,
          duration_of_session: duration_of_session,
          medical_prescription:
            medical_prescription.length > 0
              ? medical_prescription.map(e => ({
                  formulation: e.formulation,
                  composition: e.composition,
                  brand: e.brand,
                  dose: e.dose,
                  frequency: e.frequency,
                  days: e.days,
                  notes: e.notes,
                }))
              : [],
          //notes: notes,
          followup_session: followup_session,
          plan_for_the_next_session: plan_for_the_next_session,
          referral: referral,
          special_advice: special_advice,
          to_chemist: to_chemist,
          radiology:
            radiology &&
            radiology.map(e => ({ label: e.label, value: e.value })),
          lab_investigations:
            labinvestigation &&
            labinvestigation.map(e => ({ label: e.label, value: e.value })),
          technique: techniques && techniques.map(e => e.value),
          conduct: conduct && conduct.map(e => e.value),
          medical_plan: medicinalPlan && medicinalPlan.map(e => e.value),
          theray_method: theray_method && theray_method.value,
          therapy_technique: therapy_technique,
        }

        if (
          (formdata.referral === 'Clinical Psychologist' ||
            formdata.referral === 'Psychiatrist') &&
          !formdata.referral_doctor_id
        ) {
          this.setState({
            errors: {
              ...this.state.errors,
              referral_doctor_id: 'Field is required',
            },
          })
          return
        }

        const newData = _.pickBy(formdata, _.identity)
        console.log("newData---->", newData)
        this.props.addPrescription(newData, id, this.callback)
      }
    )
  }
  callback = res => {
    if (res && res.status === 200) {
      this.props.loadForAppointment(this.props.id)
      this.props.loadList({ appointment_date: this.props.appointment_date })
      this.props.loadForPatient(this.props.patient_id)
      toast(<AlertSuccess message={'Information Saved.'} />)
      this.setState({
        data: {
          day: '',
          duration_of_session: '',
          diagnosis: '',
          frequency: '',
          notes: '',
          plan_for_the_next_session: '',
          followup_session: '',
          referral: '',
          to_chemist: '',
          special_advice: '',
          formulation: '',
          composition: {},
          brand: '',
          dose: '',
          radiology: [],
          techniques: [],
          medicinalPlan: [],
          conduct: [],
          theray_method: {},
          therapy_technique: [],
          labinvestigation: [],
          key_issue: '',
          notes_data: '',
          chief_complaints: '',
          objective_of_the_next_session: '',
          referral_doctor_id: '',
          nextFollowUp: '',
        },
        technique: [],
        objective: [],
        session_participants: [],
        medical_prescription: [],
        is_submited: false,
        errors: {},
        selectPrescription: false,
        presId: '',
      })
      this.props.toggle2(this.props.id)
      if (this.state.toolbox)
        this.props.history.push('/doctor/dashboard/toolbox')
    } else if (res && res.data.error === 'Validation error.') {
      const errors = { ...this.state.errors }
      if (!this.state.data.diagnosis)
        errors.diagnosis = 'Diagnosis field is required.'
      if (!this.state.data.followup_session)
        errors.followup_session = 'Follow Up session field is required.'
      if (!this.state.data.plan_for_the_next_session)
        errors.plan_for_the_next_session =
          'Plan for the next session field is required.'
      if (!this.state.data.referral)
        errors.referral = 'Referral field is required.'
      if (!this.state.data.to_chemist)
        errors.to_chemist = 'To chemist field is required.'
      if (!this.state.data.special_advice)
        errors.special_advice = 'Special field is required.'
      this.setState({ errors, toolbox: false })
    }
  }
  toggleClose = () => {
    this.props.toggle2()
    this.setState({
      data: {
        day: '',
        duration_of_session: '',
        diagnosis: '',
        frequency: '',
        notes: '',
        plan_for_the_next_session: '',
        followup_session: '',
        referral: '',
        to_chemist: '',
        special_advice: '',
        formulation: '',
        composition: {},
        brand: '',
        dose: '',
        radiology: [],
        techniques: [],
        medicinalPlan: [],
        conduct: [],
        theray_method: {},
        therapy_technique: [],
        labinvestigation: [],
        key_issue: '',
        notes_data: '',
        chief_complaints: '',
        objective_of_the_next_session: '',
        referral_doctor_id: '',
        nextFollowUp: '',
      },
      technique: [],
      objective: [],
      session_participants: [],
      medical_prescription: [],
      is_submited: false,
      errors: {},
      selectPrescription: false,
      presId: '',
    })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.id && this.props.id !== prevProps.id) {
      this.props.loadForAppointment(this.props.id)
    }
    if (this.props.caseRecord != prevProps.caseRecord) {
      const data = { ...this.state.data }
      data.diagnosis = this.props.caseRecord

      this.setState({ data })
    }
    if (this.props.forAppointment !== prevProps.forAppointment) {
      if (this.props.forAppointment) {
        const data = { ...this.state.data }
        data.duration_of_session =
          this.props.forAppointment.duration_of_session &&
          this.props.forAppointment.duration_of_session
        data.day = this.props.forAppointment.day
        data.diagnosis = this.props.caseRecord
          ? this.props.caseRecord
          : this.props.forAppointment.diagnosis
        data.frequency = this.props.forAppointment.frequency
        data.plan_for_the_next_session =
          this.props.forAppointment.plan_for_the_next_session
        data.followup_session = this.props.forAppointment.followup_session
        data.referral = this.props.forAppointment.referral
        data.to_chemist = this.props.forAppointment.to_chemist
        data.chief_complaints = this.props.forAppointment.chief_complaints
        data.notes_data = this.props.forAppointment.notes_data
        data.special_advice = this.props.forAppointment.special_advice
        data.key_issue = this.props.forAppointment.key_issue
        data.techniques =
          this.props.forAppointment.techniques &&
          this.props.forAppointment.techniques.map(e => ({
            label: e,
            value: e,
          }))
        data.conduct =
          this.props.forAppointment.conduct &&
          this.props.forAppointment.conduct.map(e => ({ label: e, value: e }))
        data.medicinalPlan =
          this.props.forAppointment.medical_plan &&
          this.props.forAppointment.medical_plan.map(e => ({
            label: e,
            value: e,
          }))
        data.radiology =
          this.props.forAppointment.radiology &&
          this.props.forAppointment.radiology.map(e => ({
            label: e.label,
            value: e.value,
          }))
        data.labinvestigation =
          this.props.forAppointment.lab_investigations &&
          this.props.forAppointment.lab_investigations.map(e => ({
            label: e.label,
            value: e.value,
          }))
        if (data.theray_method)
          data.theray_method.label =
            this.props.forAppointment.theray_method &&
            this.props.forAppointment.theray_method
        if (data.theray_method)
          data.theray_method.value =
            this.props.forAppointment.theray_method &&
            this.props.forAppointment.theray_method
        data.therapy_technique = this.props.forAppointment.therapy_technique
          ? this.props.forAppointment.therapy_technique
          : []
        data.notes =
          this.props.forAppointment.medical_prescription &&
          this.props.forAppointment.medical_prescription[0] &&
          this.props.forAppointment.medical_prescription[0].notes
        data.nextFollowUp = this.props.forAppointment.nextFollowUp
          ? moment().add(this.props.forAppointment.followup_session, 'days').format('YYYY-MM-DD')
          : ''
        data.referral_doctor_id = this.props.forAppointment.referral_doctor_id
          ? this.props.forAppointment.referral_doctor_id
          : 'any'

        const findDataVal = this.props.fieldTypes.find(
          ele =>
            ele.name ===
            (this.props.forAppointment.referral === 'Psychiatrist'
              ? 'Psychiatrist'
              : 'Psychologist')
        )
        this.props.loadDoctors({
          'field_type._id': findDataVal.id,
          'totalCount': 1000,
        })

        const objective = this.props.forAppointment.objective

        this.setState({
          objective,
          data,
          is_submitted:
            this.state.selectPrescription && this.state.presId
              ? false
              : this.props.forAppointment.is_submited,
          medical_prescription: this.props.forAppointment.medical_prescription,
          session_participants: this.props.forAppointment.session_participants,
        })
      } else {
        this.setState({
          data: {
            day: '',
            duration_of_session: '',
            diagnosis: '',
            frequency: '',
            notes: '',
            plan_for_the_next_session: '',
            followup_session: '',
            referral: '',
            to_chemist: '',
            special_advice: '',
            formulation: '',
            composition: {},
            brand: '',
            dose: '',
            radiology: [],
            techniques: [],
            medicinalPlan: [],
            conduct: [],
            theray_method: {},
            therapy_technique: [],
            labinvestigation: [],
            key_issue: '',
            notes_data: '',
            chief_complaints: '',
            objective_of_the_next_session: '',
            referral_doctor_id: '',
            nextFollowUp: '',
          },
          session_participants: [],
          medical_prescription: [],
          is_submited: false,
        })
      }
    }
    if (
      this.state.data.theray_method &&
      this.state.data.theray_method.value &&
      prevState.data.theray_method &&
      prevState.data.theray_method.value &&
      this.state.data.theray_method.value != prevState.data.theray_method.value
    ) {
      const parent = this.props.therapy.find(
        e => e.title === this.state.data.theray_method.value
      )
      this.setState({ technique: parent && parent.techniques })
    }
  }
  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  handlePrescriptionChange = e => {
    const { value } = e
    this.setState({ presId: value })
    this.props.loadForAppointment(value, this.handleAppointmentCallback)
  }

  handleAppointmentCallback = res => {
    if (res && res.status == 200 && res.data && res.data.data) {
      const data = { ...this.state.data }
      const appointmentData = res.data.data
      data.duration_of_session =
        appointmentData.duration_of_session &&
        appointmentData.duration_of_session
      data.day = appointmentData.day
      data.diagnosis = this.props.caseRecord
        ? this.props.caseRecord
        : appointmentData.diagnosis
      data.frequency = appointmentData.frequency
      data.plan_for_the_next_session = appointmentData.plan_for_the_next_session
      data.followup_session = appointmentData.followup_session
      data.referral = appointmentData.referral
      data.to_chemist = appointmentData.to_chemist
      data.chief_complaints = appointmentData.chief_complaints
      data.notes_data = appointmentData.notes_data
      data.special_advice = appointmentData.special_advice
      data.key_issue = appointmentData.key_issue
      data.techniques =
        appointmentData.techniques &&
        appointmentData.techniques.map(e => ({
          label: e,
          value: e,
        }))
      data.conduct =
        appointmentData.conduct &&
        appointmentData.conduct.map(e => ({ label: e, value: e }))
      data.medicinalPlan =
        appointmentData.medical_plan &&
        appointmentData.medical_plan.map(e => ({
          label: e,
          value: e,
        }))
      data.radiology =
        appointmentData.radiology &&
        appointmentData.radiology.map(e => ({
          label: e.label,
          value: e.value,
        }))
      data.labinvestigation =
        appointmentData.lab_investigations &&
        appointmentData.lab_investigations.map(e => ({
          label: e.label,
          value: e.value,
        }))
      if (data.theray_method)
        data.theray_method.label = appointmentData.theray_method
          ? appointmentData.theray_method
          : ''
      if (data.theray_method)
        data.theray_method.value = appointmentData.theray_method
          ? appointmentData.theray_method
          : ''
      data.therapy_technique = appointmentData.therapy_technique
        ? appointmentData.therapy_technique
        : []
      data.notes =
        appointmentData.medical_prescription &&
        appointmentData.medical_prescription[0] &&
        appointmentData.medical_prescription[0].notes
      data.nextFollowUp = this.props.forAppointment.nextFollowUp
        ? moment().add(this.props.forAppointment.followup_session, 'days').format('YYYY-MM-DD')
        : ''
      data.referral_doctor_id = this.props.forAppointment.referral_doctor_id
        ? this.props.forAppointment.referral_doctor_id
        : 'any'

      const findDataVal = this.props.fieldTypes.find(
        ele =>
          ele.name ===
          (this.props.forAppointment.referral === 'Psychiatrist'
            ? 'Psychiatrist'
            : 'Psychologist')
      )
      this.props.loadDoctors({
        'field_type._id': findDataVal.id,
        'totalCount': 1000,
      })

      const objective = appointmentData.objective

      this.setState({
        objective,
        data,
        medical_prescription: appointmentData.medical_prescription,
        session_participants: appointmentData.session_participants,
        // is_submitted:
        //   this.state.selectPrescription && this.state.presId
        //     ? false
        //     : appointmentData.is_submited,
      })
    }
  }

  render() {
    const {
      formula,
      composition,
      brand,
      dose,
      radiology,
      lab,
      frequency,
      prescriptionData,
    } = this.props

    const doctorFindData =
      this.state.data.referral_doctor_id == 'any' &&
      (this.state.data.referral === 'Clinical Psychologist' ||
        this.state.data.referral === 'Psychiatrist')
        ? {
            name: 'Any',
          }
        : (this.state.data.referral_doctor_id && this.state.data.referral_doctor_id._id?this.props.doctorList.data.find(
          ele => ele._id == this.state.data.referral_doctor_id._id,
        ):null)
    const referralDoctorObj = doctorFindData
      ? {
          label: doctorFindData.name,
          value:
            this.state.data.referral_doctor_id == null
              ? 'any'
              : this.state.data.referral_doctor_id,
        }
      : { label: 'Any'}
    return (
      <>
        <div
          class='sidebar-box list-box'
          id='prescriptions_side_panel_1'
          style={{ right: this.props.isShow2 === true ? '0px' : '-100%' }}>
          <div class='sidebar-head d-flex align-items-center'>
            <h5>Prescriptions </h5>
            <div class='sidebar-right ml-auto d-flex align-items-centers'>
              <div class='close-img' onClick={this.toggleClose}>
                <img src={close} alt='' />
              </div>
            </div>
          </div>
          <div class='sidebar-body height-auto p-0'>
            <div class='sidebar-inner-content'>
              <div class='sic-head d-flex flex-wrap justify-content-between'>
                <div class='sic-head-left flex-grow-1 d-flex '>
                  <div class='sic-head-img'>
                    {this.props.image ? (
                      <img
                        src={process.env.REACT_APP_S3URL + this.props.image}
                        alt=''
                      />
                    ) : (
                      <AvtarImage />
                    )}
                  </div>
                  <div class='sic-head-text'>
                    <h4>
                      {this.props.first_name + ' ' + this.props.last_name}{' '}
                      <i>
                        {' '}
                        {this.props.star === true ? (
                          <img src={bluestar} alt='star-icon' />
                        ) : (
                          <img src={star} alt='star-icon' />
                        )}
                      </i>
                    </h4>
                    <p>{this.props.yesmindy_identification_number}</p>
                  </div>
                </div>
              </div>
              <div class='sic-body Prescriptions-box'>
                <div class='chats-head d-flex align-items-center'>
                  <div class='d-flex align-items-center'>
                    <div class='slb-img'>
                      <img src={file} alt='' />
                    </div>
                    <div class='doc-text-box'>
                      <h4>New Prescriptions {this.props.number}</h4>
                      <p>{moment(this.props.time).utc().format('lll')}</p>
                    </div>
                    {prescriptionData && prescriptionData.length > 0 ? (
                      <div class='doc-text-box ml-4'>
                        <div className='schedule-day'>
                          <div className='form-check'>
                            <input
                              name='mon'
                              onChange={e => {
                                if (!e.target.checked) {
                                  this.setState({
                                    data: {
                                      day: '',
                                      duration_of_session: '',
                                      diagnosis: '',
                                      frequency: '',
                                      notes: '',
                                      plan_for_the_next_session: '',
                                      followup_session: '',
                                      referral: '',
                                      to_chemist: '',
                                      special_advice: '',
                                      formulation: '',
                                      composition: {},
                                      brand: '',
                                      dose: '',
                                      radiology: [],
                                      techniques: [],
                                      medicinalPlan: [],
                                      conduct: [],
                                      theray_method: {},
                                      therapy_technique: [],
                                      labinvestigation: [],
                                      key_issue: '',
                                      notes_data: '',
                                      chief_complaints: '',
                                      objective_of_the_next_session: '',
                                      referral_doctor_id: '',
                                      nextFollowUp: '',
                                    },
                                    technique: [],
                                    objective: [],
                                    session_participants: [],
                                    medical_prescription: [],
                                    is_submited: false,
                                    errors: {},
                                    selectPrescription: e.target.checked,
                                    presId: '',
                                  })
                                } else {
                                  this.setState({
                                    selectPrescription: e.target.checked,
                                    presId: '',
                                  })
                                }
                              }}
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='flexCheckMon'
                              checked={this.state.selectPrescription}
                            />
                            <label
                              style={{ color: '#582bb8', fontWeight: 'bold' }}
                              className='form-check-label ml-0'
                              htmlFor='flexCheckMon'>
                              Copy Previous Prescription
                            </label>
                          </div>
                        </div>
                        <SelectOptions
                          name='startTime'
                          attrind={0}
                          attrnum='0'
                          onChange={this.handlePrescriptionChange}
                          value={prescriptionData.find(
                            es => es.value === this.state.presId
                          )}
                          isDisabled={!this.state.selectPrescription}
                          nameCl='ml-0'
                          options={prescriptionData}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div class='sidebar-info-box d-flex flex-wrap'>
                  <div class='sib-left'>
                    <h5>
                      {this.props.first_name + ' ' + this.props.last_name}
                    </h5>
                    <p>Age: {moment().diff(this.props.age, 'years')} yrs</p>
                    <p> Gender: {this.props.sex}</p>
                  </div>
                  <div class='sib-right'>
                    <h5>
                      <small>
                        UHID: {this.props.yesmindy_identification_number}
                      </small>
                    </h5>
                    <p>Session Date:</p>
                    <p>{moment(this.props.time).utc().format('lll')}</p>
                  </div>
                </div>
                <div class='radio-group-box'>
                  <h5>Duration of session</h5>
                  <div class='radio-group-row d-flex flex-wrap align-items-center'>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Duration'
                        id='50 min'
                        onChange={e => this.toggleDuration(e)}
                        checked={
                          this.state.data.duration_of_session === '50 min'
                        }
                      />
                      <label class='form-check-label' htmlFor='50 min'>
                        50 min
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Duration'
                        id='Less than 50 Min'
                        onChange={e => this.toggleDuration(e)}
                        checked={
                          this.state.data.duration_of_session ===
                          'Less than 50 Min'
                        }
                      />
                      <label
                        class='form-check-label'
                        htmlFor='Less than 50 Min'>
                        Less than 50 Min
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Duration'
                        id='More than 50 Min'
                        onChange={e => this.toggleDuration(e)}
                        checked={
                          this.state.data.duration_of_session ===
                          'More than 50 Min'
                        }
                      />
                      <label
                        class='form-check-label'
                        htmlFor='More than 50 Min'>
                        More than 50 Min
                      </label>
                    </div>
                  </div>
                  <div className='form-group custom-input'>
                    {this.state.errors.duration_of_session && (
                      <label className='error'>
                        {this.state.errors.duration_of_session}
                      </label>
                    )}
                  </div>
                </div>
                <div class='radio-group-box'>
                  <h5>Session participants:</h5>
                  <div class='radio-group-row d-flex flex-wrap align-items-center'>
                    {participants.map(e => (
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          checked={this.state.session_participants.find(
                            el => el === e.Participants
                          )}
                          id={e.Participants}
                          onChange={() => this.changeLang(e.Participants)}
                        />
                        <label
                          class='form-check-label ml-0'
                          htmlFor={e.Participants}>
                          {e.Participants}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div class='radio-group-box'>
                  <div class='input-group-row'>
                    <h5>Diagnosis:</h5>
                    {this.renderPrescription('diagnosis', '', 'text', true)}
                  </div>
                </div>

                {this.props.type === 'Psychologist' ? (
                  <div class='radio-group-box'>
                    <div class='input-group-row'>
                      <h5>Chief Complaints</h5>
                      <div class='form-group custom-input mb-0'>
                        {this.renderTextarea('chief_complaints', '')}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div class='radio-group-box'>
                      <div class='input-group-row'>
                        <h5>Chief Complaints</h5>
                        <div class='form-group custom-input mb-0'>
                          {this.renderTextarea('chief_complaints', '')}
                        </div>
                      </div>
                    </div>
                    <div class='sidebar-bg-box'>
                      <div class='box-h-line'>
                        <span>Rx</span>
                      </div>
                      <div class='radio-group-box'>
                        <div class='input-group-row ig-column-outer d-flex flex-wrap'>
                          <div class='ig-column'>
                            <h5>Formulation</h5>
                            <div class='form-group show'>
                              <Select
                                isClearable
                                styles={this.customStyles}
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data.formulation = e || ''
                                  this.setState({ data })

                                  const errors = { ...this.state.errors };
                                  if (!data.formulation) {
                                    delete errors.formulation;
                                  }
                                  this.setState({ errors });
                                }}
                                placeholder=''
                                value={this.state.data.formulation}
                                options={formula.map(e => ({
                                  label: e.title,
                                  value: e._id,
                                }))}
                              />
                            </div>
                            <div className='form-group custom-input'>
                              {this.state.errors.formulation && (
                                <label className='error'>
                                  {this.state.errors.formulation}
                                </label>
                              )}
                            </div>
                          </div>
                          <div class='ig-column'>
                            <h5>Composition Name</h5>
                            <div class='form-group show'>
                              <CreatableSelect
                                isClearable
                                styles={this.customStyles}
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data.composition = e || ''
                                  data.brand = {}
                                  data.dose = {}
                                  this.setState({ data })

                                  const errors = { ...this.state.errors };
                                  if (!data.composition) {
                                    delete errors.composition;
                                  }
                                  this.setState({ errors });

                                  if (data.composition) {
                                    this.props.loadBrand({
                                      composition: data.composition.label,
                                    })
                                    this.props.loadDose({
                                      composition: data.composition.label,
                                    })
                                  }
                                }}
                                placeholder=''
                                onCreateOption={this.handleCreatecomposition}
                                value={this.state.data.composition}
                                options={composition.map(e => ({
                                  label: e.title && e.title.toUpperCase(),
                                  value: e._id,
                                }))}
                              />
                            </div>
                            <div className='form-group custom-input'>
                              {this.state.errors.composition && (
                                <label className='error'>
                                  {this.state.errors.composition}
                                </label>
                              )}
                            </div>
                          </div>
                          <div class='ig-column'>
                            <h5>Brand Name</h5>
                            <div class='form-group show'>
                              <CreatableSelect
                                isClearable
                                isDisabled={
                                  !this.state.data.composition ||
                                  !this.state.data.composition.label
                                }
                                styles={this.customStyles}
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data.brand = e
                                  this.setState({ data })
                                }}
                                onCreateOption={this.handleCreatebrand}
                                value={this.state.data.brand}
                                placeholder=''
                                options={brand.map(e => ({
                                  label: e.title.toUpperCase(),
                                  value: e._id,
                                }))}
                              />
                            </div>
                            <div className='form-group custom-input'>
                              {this.state.errors.brand && (
                                <label className='error'>
                                  {this.state.errors.brand}
                                </label>
                              )}
                            </div>
                          </div>
                          <div class='ig-column'>
                            <h5>Dose</h5> 
                            <div class='form-group show'>
                              <CreatableSelect
                                isClearable
                                isDisabled={
                                  !this.state.data.composition ||
                                  !this.state.data.composition.label
                                }
                                styles={this.customStyles}
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data.dose = e
                                  this.setState({ data })
                                }}
                                onCreateOption={this.handleCreatedose}
                                value={this.state.data.dose}
                                options={dose.map(e => ({
                                  label: e.title.toUpperCase(),
                                  value: e._id,
                                }))}
                                placeholder=''
                              />
                            </div>
                            <div className='form-group custom-input'>
                              {this.state.errors.dose && (
                                <label className='error'>
                                  {this.state.errors.dose}
                                </label>
                              )}
                            </div>
                          </div>
                          <div class='ig-column'>
                            <h5>Frequency</h5>
                            <CreatableSelect
                              isClearable
                              key={
                                this.state.data.frequency &&
                                this.state.data.frequency.length
                              }
                              styles={this.customStyles}
                              onChange={e => {
                                const data = { ...this.state.data }
                                data.frequency = e
                                this.setState({ data })
                              }}
                              isMulti
                              onCreateOption={this.handleCreateFrequency}
                              defaultValue={
                                this.state.data.frequency &&
                                this.state.data.frequency
                              }
                              options={frequency.map(e => ({
                                label: e.title.toUpperCase(),
                                value: e._id,
                              }))}
                            />
                            {/* <Select
                              key={
                                this.state.data.frequency &&
                                this.state.data.frequency.length
                              }
                              onChange={(e) => {
                                const data = { ...this.state.data };
                                if (e) {
                                  data.frequency = e;
                                  this.setState({ data });
                                }
                              }}
                              placeholder=""
                              isMulti
                              styles={this.customStyles}
                              classNameName="form-groupxx"
                              closeMenuOnSelect={true}
                              components={this.animatedComponents}
                              options={this.state.frequency.map((e) => ({
                                label: e.id.toUpperCase(),
                                value: e.name,
                              }))}
                              defaultValue={this.state.data.frequency}
                            /> */}
                          </div>
                          <div class='ig-column'>
                            <h5>Days</h5>
                            {this.renderPrescription('day', '')}
                          </div>
                        </div>
                      </div>
                      <div class='radio-group-box'>
                        <div class='input-group-row'>
                          <h5>Notes</h5>
                          <div class='form-group custom-input mb-0'>
                            {this.renderPrescription('notes', '')}
                          </div>
                        </div>
                      </div>
                      <div class='class-plan-btn-group d-md-flex flex-wrap'>
                        <div class='plan-group-column'>
                          <a
                            href='javascript:void(0)'
                            onClick={this.addData}
                            class='btn btn-add'>
                            {/* <span>
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </span> */}
                            Add{' '}
                          </a>
                        </div>
                      </div>
                      {this.state.medical_prescription &&
                        this.state.medical_prescription.length > 0 && (
                          <>
                            <div class='box-h-line'>
                              <span>Rx</span>
                            </div>
                            <table class='table small-table table-responsive'>
                              <thead>
                                <tr>
                                  <th>Formulation</th>
                                  <th>Composition</th>
                                  <th>Brand</th>
                                  <th>Dose</th>
                                  <th>Frequency</th>
                                  <th>Days</th>
                                  <th width='15%'>Note</th>
                                  <th width='5%'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.medical_prescription.map(
                                  (e3, i) => (
                                    <tr>
                                      <td>{e3.formulation}</td>
                                      <td>{e3.composition}</td>
                                      <td>{e3.brand}</td>
                                      <td>{e3.dose}</td>
                                      <td>
                                        {e3.frequency &&
                                          e3.frequency.map(em => (
                                            <>
                                              {em.label ? em.label : em.value}
                                              <br />
                                            </>
                                          ))}
                                      </td>
                                      <td>{e3.days}</td>
                                      <td>{e3.notes}</td>
                                      <td>
                                        <i
                                          style={{
                                            cursor: 'pointer',
                                            color: 'red',
                                            marginRight: 5,
                                          }}
                                          class='fa fa-trash'
                                          aria-hidden='true'
                                          onClick={e => {
                                            const medical_prescription = [
                                              ...this.state
                                                .medical_prescription,
                                            ]
                                            const dataMedi =
                                              medical_prescription.filter(
                                                (ep, il) => il !== i
                                              )
                                            this.setState({
                                              medical_prescription: dataMedi,
                                            })
                                          }}></i>
                                        <i
                                          style={{
                                            cursor: 'pointer',
                                            marginLeft: '5px',
                                          }}
                                          class='fa fa-edit'
                                          aria-hidden='true'
                                          onClick={e => {
                                            this.editData(i)
                                          }}></i>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </>
                        )}
                    </div>
                  </>
                )}
                {this.props.type === 'Psychologist' ? (
                  ''
                ) : (
                  <div class='input-group-row ig-multiple-column d-flex flex-wrap'>
                    <div
                      class='ig-column'
                      style={{
                        width: '50%',
                        paddingLeft: '0px',
                        paddingTop: '0px',
                      }}>
                      <h5>Radiology</h5>
                      <div class='form-group show'>
                        <CreatableSelect
                          isClearable
                          key={
                            this.state.data.radiology &&
                            this.state.data.radiology.length
                          }
                          styles={this.customStyles}
                          onChange={e => {
                            const data = { ...this.state.data }
                            data.radiology = e
                            this.setState({ data })
                          }}
                          onCreateOption={this.handleCreateradiology}
                          isMulti
                          defaultValue={
                            this.state.data.radiology &&
                            this.state.data.radiology
                          }
                          options={radiology.map(e => ({
                            label: e.title.toUpperCase(),
                            value: e._id,
                          }))}
                        />
                      </div>
                      <div className='form-group custom-input'>
                        {this.state.errors.radiology && (
                          <label className='error'>
                            {this.state.errors.radiology}
                          </label>
                        )}
                      </div>
                    </div>
                    <div
                      class='ig-column'
                      style={{
                        width: '50%',
                        paddingLeft: '0px',
                        paddingTop: '0px',
                      }}>
                      <h5>Lab Investigation</h5>
                      <div class='form-group show'>
                        <CreatableSelect
                          isClearable
                          key={
                            this.state.data.labinvestigation &&
                            this.state.data.labinvestigation.length
                          }
                          styles={this.customStyles}
                          onChange={e => {
                            const data = { ...this.state.data }
                            data.labinvestigation = e
                            this.setState({ data })
                          }}
                          isMulti
                          onCreateOption={this.handleCreatelabinvestigation}
                          defaultValue={
                            this.state.data.labinvestigation &&
                            this.state.data.labinvestigation
                          }
                          options={lab.map(e => ({
                            label: e.title.toUpperCase(),
                            value: e._id,
                          }))}
                        />
                      </div>
                      <div className='form-group custom-input'>
                        {this.state.errors.labinvestigation && (
                          <label className='error'>
                            {this.state.errors.labinvestigation}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div class=' radio-group-box-outer outer '>
                  {this.props.type === 'Psychologist' && (
                    <>
                      <div class='class-plan-btn-group d-md-flex flex-wrap'>
                        <div
                          class='ig-column'
                          style={{
                            width: '50%',
                            paddingLeft: '0px',
                            paddingTop: '0px',
                          }}>
                          <h5>Therapy Method</h5>
                          <div class='form-group show'>
                            <Select
                              key={this.state.data.theray_method.length}
                              onChange={e => {
                                const data = { ...this.state.data }
                                if (e) {
                                  data.theray_method = e
                                  this.setState({ data })
                                }
                              }}
                              placeholder='Select Multiple Options'
                              styles={this.customStyles}
                              classNameName='form-groupxx'
                              closeMenuOnSelect={true}
                              components={this.animatedComponents}
                              options={this.props.therapy.map(e => ({
                                label: e.title,
                                value: e.title,
                              }))}
                              value={this.state.data.theray_method}
                            />
                          </div>
                        </div>

                        <div
                          class='ig-column'
                          style={{
                            width: '50%',
                            paddingLeft: '0px',
                            paddingTop: '0px',
                          }}>
                          <h5>Techniques</h5>
                          <div class='form-group show'>
                            <Select
                              isMulti
                              key={
                                this.state.technique
                                  ? this.state.technique.length
                                  : 1
                              }
                              onChange={e => {
                                const data = { ...this.state.data }

                                if (e && e.length === 4) return
                                if (e) {
                                  data.therapy_technique = e
                                  this.setState({ data })
                                } else {
                                  data.therapy_technique = []
                                  this.setState({ data })
                                }
                              }}
                              placeholder='Select Multiple Options'
                              styles={this.customStyles}
                              classNameName='form-groupxx'
                              closeMenuOnSelect={true}
                              components={this.animatedComponents}
                              options={
                                this.state.technique &&
                                this.state.technique.map(e => ({
                                  label: e,
                                  value: e,
                                }))
                              }
                              value={this.state.data.therapy_technique}
                            />
                          </div>
                        </div>
                      </div>
                      <div class='radio-group-box'>
                        {this.props.type === 'Psychologist' && (
                          <div class='radio-group-box'>
                            <div class='input-group-row'>
                              <h5>Others:</h5>
                              <div class='form-group custom-input mb-0'>
                                {this.renderPrescription('to_chemist', '')}
                              </div>
                            </div>
                          </div>
                        )}
                        <div class='input-group-row'>
                          <h5>Objective of the session:</h5>
                          <div class='form-group custom-input mb-0'>
                            {this.renderPrescription(
                              'objective_of_the_next_session',
                              ''
                            )}
                          </div>
                        </div>
                        <div class='plan-group-column'>
                          <a
                            href=''
                            onClick={e => {
                              e.preventDefault()
                              const data = { ...this.state.data }
                              const objective = [...this.state.objective]
                              if (data.objective_of_the_next_session.trim()) {
                                objective.push(
                                  data.objective_of_the_next_session.trim()
                                )
                                data.objective_of_the_next_session = ''
                                this.setState({ data, objective })
                              }
                            }}
                            class='btn btn-add'>
                            Add
                          </a>
                          <ol
                            style={{
                              paddingLeft: '21px',
                              marginTop: '10px',
                            }}>
                            {this.state.objective.map(o => (
                              <li>{o}</li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </>
                  )}
                  {this.props.type === 'Psychologist' && (
                    <div class='radio-group-box'>
                      <div class='input-group-row'>
                        <h5>Key issue/Themes</h5>
                        <div class='form-group custom-input mb-0'>
                          {this.renderPrescription('key_issue', '')}
                        </div>
                      </div>
                    </div>
                  )}
                  <div class='radio-group-box'>
                    <div class='input-group-row'>
                      <h5>Plan for the next session:</h5>
                      <div class='form-group custom-input mb-0'>
                        {this.renderPrescription(
                          'plan_for_the_next_session',
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div class='radio-group-box'>
                    <div class='input-group-row'>
                      <h5>Follow up session:</h5>
                      <div class='form-group custom-input mb-0'>
                        {/* {this.renderPrescription(
                          'followup_session',
                          '',
                          'number'
                        )} */}
                        <div className={this.state.formclass}>
                          <label htmlFor={'followup_session'}></label>
                          <input
                            type='number'
                            name={'followup_session'}
                            id={'followup_session'}
                            className={
                              this.state.errors.followup_session
                                ? 'form-control error prescription-form'
                                : 'form-control prescription-form'
                            }
                            value={this.state.data.followup_session}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur2}
                            onChange={e => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  followup_session: e.target.value,
                                },
                              })
                            }}
                          />
                          {this.state.data.nextFollowUp ? (
                            <h5 className='pt-1'>
                              ({this.state.data.nextFollowUp})
                            </h5>
                          ) : null}
                          {this.state.errors &&
                            this.state.errors.followup_session && (
                              <label className='error'>
                                {this.state.errors.followup_session}
                              </label>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='radio-group-box'>
                    <div class='input-group-row'>
                      <h5>
                        {this.props.type !== 'Psychologist'
                          ? 'Special instructions'
                          : 'Task alloted'}
                      </h5>
                      <div class='form-group custom-input mb-0'>
                        {this.renderPrescription('special_advice', '')}
                      </div>
                    </div>
                  </div>

                  <div class='radio-group-box'>
                    <div class='input-group-row'>
                      <h5>Referral, If any</h5>
                      <div class='form-group show'>
                        <Select
                          onChange={e => {
                            const data = { ...this.state.data }
                            data.referral = e.value
                            data.referral_doctor_id = 'any'
                            if (
                              e.value === 'Clinical Psychologist' ||
                              e.value === 'Psychiatrist'
                            ) {
                              const findDataVal = this.props.fieldTypes.find(
                                ele =>
                                  ele.name ===
                                  (e.value === 'Psychiatrist'
                                    ? 'Psychiatrist'
                                    : 'Psychologist')
                              )
                              if (findDataVal) {
                                this.props.loadDoctors({
                                  'field_type._id': findDataVal.id,
                                  'totalCount': 1000,
                                })
                              }
                            }
                            this.setState({ data })
                          }}
                          placeholder='Select Multiple Options'
                          styles={this.customStyles}
                          classNameName='form-groupxx'
                          closeMenuOnSelect={true}
                          components={this.animatedComponents}
                          options={[
                            {
                              label: 'None',
                              value: 'None',
                            },
                            {
                              label: 'Clinical Psychologist',
                              value: 'Clinical Psychologist',
                            },
                            {
                              label: 'Psychiatrist',
                              value: 'Psychiatrist',
                            },
                            {
                              label: 'Speech Therapist',
                              value: 'Speech Therapist',
                            },
                            {
                              label: 'Occupational Therapist',
                              value: 'Occupational Therapist',
                            },
                            {
                              label: 'Physician',
                              value: 'Physician',
                            },
                            {
                              label: 'Endocrinologist',
                              value: 'Endocrinologist',
                            },
                            {
                              label: 'Cardiologist',
                              value: 'Cardiologist',
                            },
                            {
                              label: 'Dermatologist',
                              value: 'Dermatologist',
                            },
                            {
                              label: 'General Surgery',
                              value: 'General Surgery',
                            },
                            {
                              label: 'ENT',
                              value: 'ENT',
                            },
                          ]}
                          value={{
                            label: this.state.data.referral,
                            value: this.state.data.referral,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {this.props.doctorList.data &&
                  this.props.doctorList.data.length > 0 &&
                  this.state.data.referral &&
                  (this.state.data.referral === 'Clinical Psychologist' ||
                    this.state.data.referral === 'Psychiatrist') ? (
                    <div class='radio-group-box'>
                      <div class='input-group-row'>
                        <h5>Select Doctor</h5>
                        <div class='form-group custom-input show'>
                          <Select
                            onChange={e => {
                              const data = { ...this.state.data }
                              const selectedDoctor = this.props.doctorList.data.find(
                                ele => ele._id === e.value
                              );
                          
                              data.referral_doctor_id = selectedDoctor
                                ? { _id: selectedDoctor._id, name: selectedDoctor.name }
                                : { _id: 'any', name: 'Any' };
                              this.setState({ data })
                            }}
                            placeholder='Select Multiple Options'
                            styles={this.customStyles}
                            classNameName='form-groupxx'
                            closeMenuOnSelect={true}
                            components={this.animatedComponents}
                            options={[
                              { _id: 'any', name: 'Any', value: 'any' },
                              ...this.props.doctorList.data,
                            ].map(ele => ({
                              value: ele._id,
                              label: ele.name,
                            }))}
                            value={referralDoctorObj}                      
                          />
                          {this.state.errors &&
                            this.state.errors.referral_doctor_id && (
                              <label className='error'>
                                {this.state.errors.referral_doctor_id}
                              </label>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* <div class="radio-group-box">
                    <div class="input-group-row">
                      <h5>Referral, If any</h5>
                      <div class="form-group show mb-0">
                        {this.renderPrescription("referral", "")}
                      </div>
                    </div>
                  </div> */}
                  {this.props.type !== 'Psychologist' && (
                    <div class='radio-group-box'>
                      <div class='input-group-row'>
                        <h5>To chemist:</h5>
                        <div class='form-group custom-input mb-0'>
                          {this.renderPrescription('to_chemist', '')}
                        </div>
                      </div>
                    </div>
                  )}
                  <p style={{ fontSize: '11px' }}>
                    Prescription once submitted is editable for only 60 minutes
                    after submission.
                  </p>
                  <div class='prescription-btn mb-4 mb-md-0 d-flex justify-content-between align-items-center'>
                    {/* {!this.state.is_submitted && ( */}
                    <button
                      class='btn btn-outline no-shadow'
                      onClick={
                        !this.state.is_submitted ? this.handleSubmit : null
                      }>
                      Save
                    </button>
                    {/* )} */}
                    <button
                      className='btn btn-primary'
                      onClick={e => {
                        if (
                          this.props.forAppointment &&
                          this.props.forAppointment.submited_at
                        )
                          return this.handleSubmit(e)
                        if (!Object.keys(this.state.errors).length)
                          return this.setState({ popup1: true })
                      }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.props.isShow2 === true ? 'overlay3 show' : 'overlay4'
          }></div>
        <Modal
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName='modal-dialog modal-dialog-centered modal-sm'
          className='modal fade '
          show={this.state.popup1}>
          <>
            <div class='modal-header'>
              <button
                onClick={() => {
                  this.setState({ popup1: false })
                }}
                type='button'
                class='close'
                data-dismiss='modal'>
                <img src={closeIcon} alt='close popup' />
              </button>
            </div>
            <div class='modal-body'>
              <p>
                Would you like to assign any task to your Patient from your
                toolbox ?
              </p>
              <div class='modal-action-btn'>
                <a
                  href='javascript:void (0)'
                  class='btn btn-outline no-shadow'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={e => {
                    this.setState(
                      { is_submitted: true, toolbox: false },
                      () => this.handleSubmit(e),
                      this.setState({ popup1: false })
                    )
                  }}>
                  Later
                </a>
                <a
                  href='javascript:void (0)'
                  class='btn btn-primary'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={e => {
                    this.setState(
                      { is_submitted: true, toolbox: true },
                      () => this.handleSubmit(e),
                      this.setState({ popup1: false })
                    )
                  }}>
                  Go To Toolbox
                </a>
              </div>
            </div>
          </>
        </Modal>
      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  loadForPatient: id => dispatch(loadForPatient(id)),
  loadFormula: () => dispatch(loadFormula()),
  loadComposition: () => dispatch(loadComposition()),
  loadFrequency: () => dispatch(loadFrequency()),
  loadBrand: (params, call = null) => dispatch(loadBrand(params, call)),
  loadDose: (params, call = null) => dispatch(loadDose(params, call)),
  loadDay: () => dispatch(loadDay()),
  loadRadiology: () => dispatch(loadRadiology()),
  loadLabinvestigation: () => dispatch(loadLabinvestigation()),
  loadForAppointment: (id, callback = null) =>
    dispatch(loadForAppointment(id, callback)),
  addFormula: (param, callback) => dispatch(addFormula(param, callback)),
  addComposition: (param, callback) =>
    dispatch(addComposition(param, callback)),
  addFrequency: (param, callback) => dispatch(addFrequency(param, callback)),
  addBrand: (param, callback) => dispatch(addBrand(param, callback)),
  addDose: (param, callback) => dispatch(addDose(param, callback)),
  addRadiology: (param, callback) => dispatch(addRadiology(param, callback)),
  addLabinvestigation: (param, callback) =>
    dispatch(addLabinvestigation(param, callback)),
  addPrescription: (param, id, callback) =>
    dispatch(addPrescription(param, id, callback)),
  loadConducts: () => dispatch(loadConducts()),
  loadTechniques: () => dispatch(loadTechniques()),
  loadmedicinalPlan: () => dispatch(loadmedicinalPlan()),
  loadTherapy: () => dispatch(loadTherapy()),
  loadList: params => dispatch(loadList(params)),
  loadFieldType: () => dispatch(loadFieldType()),
  loadDoctors: params => dispatch(loadDoctors(params)),
})

const mapStateToProps = state => ({
  formula: getPrescription(state).formula,
  composition: getPrescription(state).composition,
  frequency: getPrescription(state).frequency,
  brand: getPrescription(state).brand,
  dose: getPrescription(state).dose,
  day: getPrescription(state).day,
  radiology: getPrescription(state).radiology,
  lab: getPrescription(state).labinvestigation,
  forAppointment: getPrescription(state).forAppointment,
  medicinalPlan: getPrescription(state).medicinalPlan,
  techniques: getPrescription(state).techniques,
  conduct: getPrescription(state).conduct,
  therapy: getFollowup(state).therapy,
  list: getPrescription(state).list,
  fieldTypes: getMisc(state).fieldTypes,
  doctorList: getDoctor(state).doctors,
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IncompletePrescription)
)
